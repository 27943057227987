var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":_vm.getTitle(this.status) +  ' Appointments',"allow-add":false,"edit-handler":_vm.edit},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"extra-actions",fn:function(ref){
var item = ref.item;
return [(_vm.status === 1)?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.changeStatus(3, item._id)}}},[_vm._v("mdi-check")]):_vm._e(),(_vm.status === 0)?_c('v-icon',{attrs:{"color":"green","small":""},on:{"click":function($event){return _vm.changeStatus(1, item._id)}}},[_vm._v("mdi-check-circle")]):_vm._e(),(_vm.status === 0)?_c('v-icon',{attrs:{"color":"red","small":""},on:{"click":function($event){return _vm.changeStatusToReject(4, item)}}},[_vm._v("mdi-close-circle")]):_vm._e(),(_vm.status === 0)?_c('v-icon',{attrs:{"color":"warning","small":""},on:{"click":function($event){return _vm.editAppointment(item)}}},[_vm._v("mdi-clock")]):_vm._e()]}},{key:"person",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.person.first_name + ' ' + item.person.last_name))])]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getAppointmentType(item.type)))])]}},{key:"service",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getServiceType(item.service)))])]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.compareDates(item.date, new Date()) ? 'chip light-green' : 'chip red'},[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}}])}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}}),_c('v-dialog',{attrs:{"width":"30%","persistent":""},model:{value:(_vm.rejectDialog),callback:function ($$v) {_vm.rejectDialog=$$v},expression:"rejectDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Reject Appointment")]),_c('div',{staticClass:"px-5 py-2"},[(_vm.editItem)?_c('v-form',{ref:"rejectForm"},[_c('v-select',{attrs:{"dense":"","items":_vm.editTimeSlots,"item-text":"from to","item-value":function (item) { return item; },"label":"Time Slot","outlined":"","return-object":"","value-comparator":function (a,b) { return a && b && a._id === b._id; }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTimeSlotValue(item.from) + ' - ' + _vm.getTimeSlotValue(item.to))+" ")])]}},{key:"item",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.getTimeSlotValue(data.item.from) + ' - ' + _vm.getTimeSlotValue(data.item.to))+" ")])]}}],null,false,3746581631),model:{value:(_vm.rejectedAppointmentsTimeSlot),callback:function ($$v) {_vm.rejectedAppointmentsTimeSlot=$$v},expression:"rejectedAppointmentsTimeSlot"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"date"},model:{value:(_vm.rejectedAppointmentsDate),callback:function ($$v) {_vm.rejectedAppointmentsDate=$$v},expression:"rejectedAppointmentsDate"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[_vm.required('Reason is required!')],"label":"Reason"},model:{value:(_vm.rejectedAppointmentsReason),callback:function ($$v) {_vm.rejectedAppointmentsReason=$$v},expression:"rejectedAppointmentsReason"}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.onCancelRejectModal}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.onSubmitRejectModal}},[_vm._v("Submit")])],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"width":"30%","persistent":""},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Update Appointment Time")]),_c('div',{staticClass:"px-5 py-2"},[(_vm.editItem)?_c('v-form',{ref:"editForm"},[_c('v-select',{attrs:{"dense":"","items":_vm.editTimeSlots,"item-text":"from to","item-value":function (item) { return item; },"label":"Time Slot","outlined":"","return-object":"","value-comparator":function (a,b) { return a && b && a._id === b._id; }},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTimeSlotValue(item.from) + ' - ' + _vm.getTimeSlotValue(item.to))+" ")])]}},{key:"item",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm.getTimeSlotValue(data.item.from) + ' - ' + _vm.getTimeSlotValue(data.item.to))+" ")])]}}],null,false,3746581631),model:{value:(_vm.editItem.time_slot),callback:function ($$v) {_vm.$set(_vm.editItem, "time_slot", $$v)},expression:"editItem.time_slot"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"date"},model:{value:(_vm.editItem.date),callback:function ($$v) {_vm.$set(_vm.editItem, "date", $$v)},expression:"editItem.date"}}),_c('v-text-field',{attrs:{"outlined":"","dense":"","rules":[_vm.required('Reason is required!')],"label":"Notes"},model:{value:(_vm.editItem.reason),callback:function ($$v) {_vm.$set(_vm.editItem, "reason", $$v)},expression:"editItem.reason"}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.cancelEditAppointment}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.submitEditAppointment}},[_vm._v("Submit")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }