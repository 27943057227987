var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dashboard-cards"},[_c('v-card',{staticClass:"pa-5 text-center blue white--text"},[_c('h1',[_vm._v(_vm._s(_vm.dashboard.pending || 0))]),_c('p',[_vm._v("Pending Appointments")])]),_c('v-card',{staticClass:"pa-5 text-center purple white--text"},[_c('h1',[_vm._v(_vm._s(_vm.dashboard.accepted || 0))]),_c('p',[_vm._v("Approved Appointments")])]),_c('v-card',{staticClass:"pa-5 text-center green white--text"},[_c('h1',[_vm._v(_vm._s(_vm.dashboard.cancelled || 0))]),_c('p',[_vm._v("Cancelled Appointments")])]),_c('v-card',{staticClass:"pa-5 text-center pink white--text"},[_c('h1',[_vm._v(_vm._s(_vm.dashboard.completed || 0))]),_c('p',[_vm._v("Completed Appointments")])])],1),_c('div',{staticClass:"pt-3"},[_c('data-table',{attrs:{"loader":_vm.loadData,"headers":_vm.headers,"title":"Today's Appointments","allow-add":false,"edit-handler":_vm.edit},on:{"done":function($event){return _vm.$router.back()}},scopedSlots:_vm._u([{key:"person",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.person.first_name + ' ' + item.person.last_name))])]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getAppointmentType(item.type)))])]}},{key:"service",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getServiceType(item.service)))])]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.compareDates(item.date, new Date()) ? 'chip light-green' : 'chip red'},[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}}])}),_c('loading-dialog',{attrs:{"message":"Loading..."},model:{value:(_vm.loadingDialog),callback:function ($$v) {_vm.loadingDialog=$$v},expression:"loadingDialog"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }